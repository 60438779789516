import {
  gql,
  MutationHookOptions,
  useMutation,
  MutationFunctionOptions,
  FetchResult,
  ApolloClient,
  NormalizedCacheObject,
} from '@apollo/client';
import { trackView } from '../__generated__/trackView';
import { TrackEventCustomVariable, TrackViewDimension } from '../__generated__/globalTypes';

export const TRACK_VIEW = gql`
  mutation trackView(
    $userToken: String!
    $url: String!
    $title: String!
    $urlReferer: String!
    $userAgent: String!
    $customDimensions: [TrackViewDimension]
    $tags: [String]
  ) {
    trackView(
      userToken: $userToken
      url: $url
      title: $title
      urlReferer: $urlReferer
      userAgent: $userAgent
      customDimensions: $customDimensions
      tags: $tags
    ) {
      error
    }
  }
`;

export const TRACK_EVENT = gql`
  mutation trackEvent(
    $userToken: String!
    $url: String!
    $eventCategory: String!
    $eventName: String!
    $eventValue: String!
    $actionName: String!
    $customDimensions: [TrackViewDimension]
    $customVariables: [TrackEventCustomVariable]
  ) {
    trackEvent(
      userToken: $userToken
      url: $url
      eventCategory: $eventCategory
      eventName: $eventName
      eventValue: $eventValue
      actionName: $actionName
      customDimensions: $customDimensions
      customVariables: $customVariables
    ) {
      error
    }
  }
`;

export const TRACK_CLICK = gql`
  mutation trackClick($userToken: String!, $urlClick: String!, $urlPage: String!) {
    trackClick(userToken: $userToken, urlClick: $urlClick, urlPage: $urlPage) {
      error
    }
  }
`;

export const trackClick = (
  client: ApolloClient<NormalizedCacheObject>,
  userToken: string,
  urlPage: string,
  urlClick: string,
) => {
  return client.mutate<string>({
    mutation: TRACK_CLICK,
    variables: { userToken, urlPage, urlClick },
  });
};

export const useTrackView = (
  options?: MutationHookOptions<
    trackView,
    {
      userToken: string;
      url: string;
      title: string;
      urlReferer?: string;
      userAgent?: string;
      customDimensions: TrackViewDimension[];
      tags: string[];
    }
  >,
): [
  (
    options?: MutationFunctionOptions<
      trackView,
      {
        userToken: string;
        url: string;
        title: string;
        urlReferer?: string;
        userAgent?: string;
        customDimensions: TrackViewDimension[];
        tags: string[];
      }
    >,
  ) => Promise<FetchResult<trackView>>,
  string | undefined,
  boolean,
] => {
  const [addPageView, { loading }] = useMutation<trackView>(TRACK_VIEW, options);

  return [addPageView, 'ok', loading];
};

export const useTrackEvent = (
  options?: MutationHookOptions<
    trackView,
    {
      url: string;
      userToken: string;
      eventCategory: string;
      eventName: string;
      eventValue?: string;
      actionName?: string;
      customDimensions: TrackViewDimension[];
      customVariables: TrackEventCustomVariable[];
    }
  >,
): [
  (
    options?: MutationFunctionOptions<
      trackView,
      {
        url: string;
        userToken: string;
        eventCategory: string;
        eventName: string;
        eventValue?: string;
        actionName?: string;
        customDimensions: TrackViewDimension[];
        customVariables: TrackEventCustomVariable[];
      }
    >,
  ) => Promise<FetchResult<trackView>>,
  string | undefined,
  boolean,
] => {
  if (typeof window === 'undefined') {
    return [() => null, 'ok', false];
  }
  // eslint-disable-next-line react-hooks/rules-of-hooks
  const [addPageEvent, { loading }] = useMutation<trackView>(TRACK_EVENT, options);

  return [addPageEvent, 'ok', loading];
};
